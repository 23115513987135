import React from "react";
import { string} from "prop-types";
import { Link } from "@quintype/components";


import "./section-category-title.m.css";

const SectionCategoryTitle = ({ name }) => {

  return (
    <div styleName="title-line" className="">
      <div styleName="hr-style" className=""/>
      <div styleName="category-title" className="category">{name}</div>
    </div>
  )

}

SectionCategoryTitle.propTypes = {
  name: string,
};
export {SectionCategoryTitle};
