/* eslint-disable import/extensions */
import React, { useState, useRef, useEffect } from "react";
import { get } from "lodash";
import PT from "prop-types";

import { HeroImage, StoryCard } from "@quintype/arrow";
import { Collection } from "@quintype/components";

import { getCollectionTemplate } from "../../get-collection-template";
import { OneBigImgNewsCols } from "../../rows/one-big-img-news-cols";
import NewestCategory from "../../story-templates/newest-category";
import StarShowHide from "../../atoms/star-show-hide";
//import Suosituimmat from "../../story-templates/suosituimmat";
import EditorialCategory from "../../story-templates/editorial-category";
import FromReaderCategory from "../../story-templates/fromreader-category";
import MenovinkitCategory from "../../story-templates/menovinkit-category";
import { noOfPages } from "../../utils/noOfPages";
import { changQueryString } from "../tag/index";
import TimeStamp from "../../atoms/time-stamp";
import CardImage from "../../atoms/card-image";
import { Link } from "@quintype/components";
import ColumnCategory from "../../story-templates/column-category";
import CardImageType8 from "../../molecules/card-image-type-8";
import { ShowMoreBtn } from "../../atoms/show-more-btn";
import SectionShowMoreStories from "../../molecules/section-show-more-stories";
import Uusimmat from "../../story-templates/uusimmat";

import "./section.m.css";
import { Suurtaulu } from "../../atoms/suurtaulu";

const storiesDom = (stories, startIndex, newestCategory) => {
  return stories.slice(startIndex, stories.length).map((story, index) => {
    const storyObj = story.story || story;
    const accessType = storyObj.access;
    const [isLogged, setIsLogged] = useState(false);
    const paaaihe = storyObj.metadata.pääaihe;
    const imgS3keys = storyObj["hero-image-s3-key"];

    /* getting url right regarding different papers*/

    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }

    let findParams = findByKey("domain-slug", "viiskunta") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts + 1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]) {
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
      //console.log("final url " + finalUrl + "/" + storySlug);
    }

    return (
      <React.Fragment key={index}>
        <div styleName="desktop-ad-slots">
          {index === 0 && (
            <div styleName="ad-container-between-articles">
              <div styleName="sidebar-ad-mobile" id="sas_63337"></div>
              {/*<div styleName="newest-category-mobile">
                <Suosituimmat />
              </div>*/}
              <div id="sas_106329" styleName="display-in-screen indexes"></div>
              <div id="sas_106330" styleName="mobile-ad indexes"></div>
            </div>
          )}
          {index === 4 && (
            <div styleName="ad-container-between-articles">
              <div id="sas_106331" styleName="display-in-screen indexes"></div>
              <div id="sas_106332" styleName="mobile-ad indexes"></div>
            </div>
          )}
        </div>

        {story["hero-image-s3-key"] !== null ?
          <div className="item" key={story.id}>
            <CardImageType8 story={story} showSubHeadline={false} />
          </div> :

          <div className="main-headline item" styleName={story["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}>
            <Link href={
              positionOfPaper !== -1 ? (
                storyObj.sections[positionOfPaper] ? (
                  `/${finalUrl}/${storySlug}`
                ) : (
                  `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
                )
              ) : (
                `/${storyObj.slug}`
              )
            }>
              <div styleName="wrapper-inside" className="wrapper-inside">

                <h2 styleName="" className="headline-layout4 main-headline-layout1 layout4">
                  <span styleName="span-newest">{story.metadata && story.metadata["pääaihe"] && `${story.metadata["pääaihe"]} | `}</span>
                  {story.headline}
                </h2>
                <div styleName="section-time-wrapper">
                  <div className="time-stamp-layout1">
                    <TimeStamp timeStamp={story["published-at"]} />
                  </div>
                  <StarShowHide accessType={story.access} />
                </div>
              </div>
            </Link>
          </div>

        }

      </React.Fragment>
    );
  });
};
const SectionPage = props => {
  const { data } = props;
  const storiesFromCollection = get(data, ["collection", "items"], []).filter(items => items.type === "story") || [];
  const collectionItems = get(data, ["collection", "items"], []).filter(items => items.type === "collection");
  const totalPages = useRef(noOfPages(data.totalCount, 30));
  const initialStories = storiesFromCollection.length > 1 ? storiesFromCollection.slice(1, 30) : [];
  const [extraStoriesData, updateExtraStoriesData] = useState(initialStories);
  const [pageNumber, updatePageNumber] = useState(Number(data.page) || 1);
  const [storyLimit, setStoryLimit] = useState(30);
  const onItemsToLoadClick = () => setStoryLimit(storyLimit + 5);
  //console.log("lista tarinoista", data);

  const collectionStory = {
    items: storiesFromCollection,
    name: data.collection.name
  };
  const collectionOfCollection = {
    items: collectionItems
  };

  async function fetchNextData() {
    const stories = await window.fetch(
      `/api/v1/collections/${data.collection.slug}?item-type=story&offset=${pageNumber * 30}&limit=80`
    );
    const response = await stories.json();
    updateExtraStoriesData([...extraStoriesData, ...response.items]);
    updatePageNumber(pageNumber + 1);
    changQueryString(pageNumber + 1);
  }


  const stories = get(data, ["collection"], ["items"]);
  const { mostPopular, newestCategory } = data;
  const [check, anyhow] = useState(true);
  const onclick = () => anyhow(false);
  const [slug, checkSlug] = useState(data.collection.slug);

  return (
    <div styleName="top-container">
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            <div styleName="mob-ad">
              <div id="sas_63333"></div>
            </div>
            <div styleName="dsktop-ad">
              <div id="sas_63219"></div>
            </div>
          </div>
          {/*<div styleName="ad-container">
          <div styleName="mob-ad">Ad Position 1 <br /> Top Ad 300*300</div>
          <div id="sas_63219" styleName="dsktop-ad"></div>
        </div>*/}

        </div>
        <div styleName="lazy-pop">
          <div styleName="section-content">
            <div styleName="section-top">
              <div styleName="section-title">
                {data.section !== undefined ?
                  (data.section["display-name"])
                  :
                  (data.collection.name.replace(/ *\([^)]*\) */g, ""))
                }
              </div>
              <div styleName="section-line"></div>
            </div>
            <div styleName="layout-template">
              {storiesFromCollection.length > 0 ? (
                collectionStory && <OneBigImgNewsCols collection={collectionStory} />
              ) : (
                <h2 styleName="no-story-headline">Ei artikkeleita...</h2>
              )}
              {/*how to add luetuimmat + uusimmat if those should have different layout:
          storiesFromCollection.length > 0 ? (
          collectionStory && slug==="most-popular" || slug==="newest" &&<Luetuimmat collection={collectionStory}/> ||
          collectionStory && <OneBigImgNewsCols collection={collectionStory}/>*/}
              {collectionItems.length >= 1 && (
                <Collection collection={collectionOfCollection} collectionTemplates={getCollectionTemplate} />
              )}
              {/*<div styleName="popular-grid-hide">
            <SideBar mostPopular={mostPopular} />
            <div styleName="sidebar-ad-hide" id="sas_76185"></div>
            <div styleName="sidebar-ad-placeholder">Sidebar Ad 300 * 600(Pystyparaati)</div>
            </div>*/}
            </div>
            <div styleName="bottom-stories-with-sidebar">
              {storiesDom(extraStoriesData, 0, newestCategory)}
              {<SectionShowMoreStories stories={storiesFromCollection} startIndex={30} itemsToLoad={storyLimit} />}
              {storiesFromCollection.length > 30 ?
                <ShowMoreBtn loadMore={onItemsToLoadClick} btnName="NÄYTÄ LISÄÄ" /> : null}
            </div>
          </div>
          <div styleName="popular">
            {/*<div styleName="sidebar-ad-placeholder">Sidebar Ad 300 * 600(Pystyparaati)</div>*/}
            <div styleName="popular-grid">
              <div styleName="sidebar-ad" id="sas_63222"></div>
              {/*<div styleName="mobile-hide-popular">
                <Suosituimmat />
              </div>*/}
              <Uusimmat />
              <EditorialCategory />
              <ColumnCategory />
              <FromReaderCategory />
              <MenovinkitCategory />
            </div>
          </div>
        </div>
      </div>
      <Suurtaulu />
    </div>
  );
};

SectionPage.propTypes = {
  data: PT.object,
  onLoadMore: PT.func,
  stories: PT.array,
  noMoreStories: PT.bool
};

export { SectionPage };
